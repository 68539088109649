import { Component, Prop, Mixins } from '@zento-lib/components';

import { FormField } from './FormField';
import { ValidatorMin, ValidatorStep, ValidatorMax } from './types';
import { ValidationMechanisms } from './validation/mechanisms';
import { ValidationMessage } from './Message';
import { InputPropagationEventType } from './Input';
import type { INumericInput } from './NumericInput.d';
import style from './style.scss';

@Component({})
export class NumericInput extends Mixins<FormField<number | any, InputPropagationEventType, INumericInput, any>>(
  FormField,
) {
  /**
   * Check if the validator step exists in the context of current field instance
   */
  protected static validatorStepExtraction(instance: NumericInput): ValidatorStep | null {
    if ('step' in instance) {
      const value = instance.step;

      // eslint-disable-next-line no-self-compare
      if (typeof value !== 'number' || value !== value) {
        throw new Error(`NumericInput: Invalid step rule value for field ${this.name}. Number expected.`);
      }

      if (value === 0) {
        throw new Error(`NumericInput: Invalid step rule value for field ${this.name}. Step can not be 0.`);
      }

      if (value === -Infinity || value === Infinity) {
        throw new Error(`NumericInput: Invalid step rule value for field ${this.name}. Step should be finite.`);
      }

      return { value };
    }

    return null;
  }

  /**
   * Check if the validator min exists in the context of current field instance
   */
  protected static validatorMinExtraction(instance: NumericInput): ValidatorMin | null {
    if ('min' in instance) {
      const value = instance.min;

      // eslint-disable-next-line no-self-compare
      if (typeof value !== 'number' || value !== value) {
        throw new Error(`NumericInput: Invalid min rule value for field ${this.name}. Number expected.`);
      }

      if (value === Infinity) {
        throw new Error(`NumericInput: Invalid min rule value for field ${this.name}. Min should be finite.`);
      }

      return { value };
    }

    return null;
  }

  /**
   * Check if the validator max exists in the context of current field instance
   */
  protected static validatorMaxExtraction(instance: NumericInput): ValidatorMax | null {
    if ('max' in instance) {
      const value = instance.max;

      // eslint-disable-next-line no-self-compare
      if (typeof value !== 'number' || value !== value) {
        throw new Error(`NumericInput: Invalid max rule value for field ${this.name}. Number expected.`);
      }

      if (value === -Infinity) {
        throw new Error(`NumericInput: Invalid max rule value for field ${this.name}. Max should be finite.`);
      }

      return { value };
    }

    return null;
  }

  /**
   * Record of validation rule names to validation parameters extraction functions
   */
  protected validationRules = {
    [FormField.RequiredRuleName]: FormField.validatorRequiredExtraction,
    min: NumericInput.validatorMinExtraction,
    max: NumericInput.validatorMaxExtraction,
    step: NumericInput.validatorStepExtraction,
  };

  protected validationMechanisms = {
    [FormField.RequiredRuleName]: ValidationMechanisms.number.required,
    min: ValidationMechanisms.number.min,
    max: ValidationMechanisms.number.max,
    step: ValidationMechanisms.number.step,
  };

  /**
   * Input type property
   */
  @Prop({ type: String, default: 'tel' })
  type?: 'tel' | 'number' | 'text';

  /**
   * Input min property
   */
  @Prop({ type: Number, default: -Infinity }) min: number;

  /**
   * Input max property
   */
  @Prop({ type: Number, default: Infinity }) max: number;

  /**
   * Input step property
   */
  @Prop({ type: Number, default: 1 }) step: number;

  /**
   * Input control property
   */
  @Prop({ type: Boolean, default: true }) controls?: boolean;

  /**
   * Input placeholder property
   */
  @Prop({ type: String, default: '' }) placeholder?: string;

  /**
   * Input slot property
   */
  @Prop({ type: Boolean, default: false }) animateLabel?: boolean;

  /**
   * Input decrement property
   */
  @Prop({ type: Boolean, default: true }) showDecrement?: boolean;

  /**
   * Forces input value update as Vue is not changing the content
   */
  @Prop({ type: Boolean, default: false }) cartProduct?: boolean;

  beforeMount() {
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  public render() {
    const pristine = this.isPristine();
    const valid = this.isValid();
    const hasValidation = this.activeRules.length > 0;

    return (
      <ValidationMessage type='number' state={this.validationState} validationParams={this.rules}>
        <div
          class={{
            [style.inputBox]: true,
            [style.inputValid]: !pristine && valid && hasValidation,
            [style.inputError]: !pristine && !valid && hasValidation,
          }}
          onClick={this.handleLabel}>
          <div class={style.numericInput}>
            {this.controls ? (
              <button
                type='button'
                name='decrement-button'
                aria-label='decrement-button'
                onClick={this.decrement}
                disabled={this.$data.value <= this.min}
                class={{ [style.btnDecrement]: true, [style.hideDecrement]: !this.showDecrement }}
                key='decrement-btn'
                data-testid='decrementButton'
              />
            ) : null}
            <input
              type={this.type}
              value={this.$data.value}
              class={{ [style.input]: true, [style.numberInput]: this.controls }}
              key={this.cartProduct ? 'numeric-input-' + this.$data.value : 'numeric-input'}
              ref='numericInput'
              onInput={this.handleInput}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onFocusOut={this.handleFocusOut}
              placeholder={this.placeholder}
              data-testid='qtyInput'
            />
            {this.controls ? (
              <button
                type='button'
                name='increment-button'
                aria-label='increment-button'
                onClick={this.increment}
                disabled={this.$data.value >= this.max}
                class={style.btnIncrement}
                key='increment-btn'
                data-testid='incrementButton'
              />
            ) : null}
          </div>
        </div>
      </ValidationMessage>
    );
  }

  /**
   * Handle input events
   */
  private handleInput(ev: InputEvent) {
    // Cache the value in local component state
    const target = ev.target as any;

    if (target && 'value' in target) {
      let value = 0;

      if (Math.floor(this.step) === this.step) {
        value = parseInt((target as any).value, 10);
      } else {
        value = parseFloat((target as any).value);
      }

      // eslint-disable-next-line no-self-compare
      this.$data.value = value === value ? value : target.value.toString();
    }

    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Handle blur events
   */
  private handleBlur(ev: Event) {
    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Handle change events
   */
  private handleChange(ev: Event) {
    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Handle focus events
   */
  private handleFocus(ev: Event) {
    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Increment the current numeric value
   */
  private increment() {
    const ogVal = this.$data.value;
    const val = ((ogVal - (ogVal % this.step)) / this.step + 1) * this.step;

    this.updateValue(val);
  }

  /**
   * Decrement the current numeric value
   */
  private decrement() {
    const ogVal = this.$data.value;
    let val = ogVal - (ogVal % this.step);

    if (ogVal === val) {
      val = (val / this.step - 1) * this.step;
    }

    this.updateValue(val);
  }

  /**
   * Update value on operation performed
   */
  private updateValue(val: number) {
    if (val >= this.max) {
      val = this.max;
    }

    if (val <= this.min) {
      val = this.min;
    }

    this.$data.value = val;

    // Manual update input value as Vue is not changing the content on this particular update
    (this.$refs.numericInput as HTMLInputElement).value = val.toString();

    this.propagateValue(this.validateOn);
  }

  /**
   * Handle click events
   */
  private handleLabel() {
    this.$data.labelPosition = true;
  }

  /**
   * Handle focus out events
   */
  private handleFocusOut() {
    this.$data.labelPosition = false;
  }
}
